import { createEffect, For, Match, Show, Suspense, Switch } from "solid-js";
import { Icon } from "solid-heroicons";
import { check, chevronLeft, chevronRight } from "solid-heroicons/outline";
import {
  AnnotationOriginStatus,
  AnnotationValuation,
  type ImageContainer,
} from "../types";

function CheckIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="h-10 w-10 absolute top-4 right-4 bg-nl-green-900 text-white rounded-full p-3"
      path={check}
    />
  );
}

function ChevronLeftIcon() {
  return (
    <Icon aria-hidden="true" class="h-16 w-16 text-white" path={chevronLeft} />
  );
}

function ChevronRightIcon() {
  return (
    <Icon aria-hidden="true" class="h-16 w-16 text-white" path={chevronRight} />
  );
}

function ViewfinderCircleIcon() {
  return (
    <svg
      aria-hidden="true"
      class="h-10 w-10 absolute top-4 right-4 bg-nl-yellow-900 text-white rounded-full p-3"
      fill="none"
      stroke="currentColor"
      stroke-width={1.5}
      viewBox="0 0 24 24"
    >
      <path d="M7.5 3.75H6C4.75736 3.75 3.75 4.75736 3.75 6V7.5M16.5 3.75H18C19.2426 3.75 20.25 4.75736 20.25 6V7.5M20.25 16.5V18C20.25 19.2426 19.2426 20.25 18 20.25H16.5M7.5 20.25H6C4.75736 20.25 3.75 19.2426 3.75 18V16.5M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
    </svg>
  );
}

interface CarouselProps {
  classes?: { [name: string]: string };
  currentImageId: string | undefined;
  images: ImageContainer[];
  imageSelectionCallback: (id: string) => void;
}

export default function Carousel(props: CarouselProps) {
  let sliderRef!: HTMLUListElement;
  // let defaultTransform = 0;

  const goNext = () => {
    console.log("goNext");
    sliderRef.scrollBy(264, 0);
    /*
    defaultTransform = defaultTransform - 398;
    if (Math.abs(defaultTransform) >= sliderRef.scrollWidth / 1.7) {
      defaultTransform = 0;
    }
    sliderRef.style.transform = "translateX(" + defaultTransform + "px)";
    */
  };

  const goPrev = () => {
    sliderRef.scrollBy(-264, 0);
    /*
    if (Math.abs(defaultTransform) === 0) {
      defaultTransform = 0;
    } else {
      defaultTransform = defaultTransform + 398;
    }
    sliderRef.style.transform = "translateX(" + defaultTransform + "px)";
    */
  };

  return (
    <div class="flex h-full items-center relative">
      <button
        aria-label="slide backward"
        class="absolute z-30 left-0 top-auto rounded focus:outline-none focus:bg-nl-gray-dark-800 focus:ring-2 focus:ring-nl-gray-dark-400 cursor-pointer"
        id="PrevImage"
        onClick={goPrev}
      >
        <ChevronLeftIcon />
      </button>

      <ul
        class="flex overflow-x-scroll p-2 gap-2 overscroll-contain touch-pan-x h-full"
        ref={sliderRef}
        style={{
          "scrollbar-width": "none",
          "-webkit-overflow-scrolling": "touch",
        }}
      >
        <Suspense>
          <For each={props.images}>
            {(item, itemIndex) => (
              <li
                classList={{
                  relative: true,
                  "border-nl-yellow-900": props.currentImageId === item.id,
                  "border-transparent": props.currentImageId !== item.id,
                  "border-4 h-full rounded-md shrink-0 w-64": true,
                }}
              >
                <img
                  class="h-full object-cover rounded-sm w-64"
                  src={item.smallURL}
                  alt=""
                  onClick={() => {
                    console.log("clicked");
                    props.imageSelectionCallback(item.id);
                  }}
                />

                <div class="absolute bottom-4 left-4 flex flex-row gap-0.5">
                  <For each={props.images[itemIndex()].annotations.items}>
                    {(aitem) => (
                      <Show
                        // Do not show circle for rejected AI
                        when={
                          (item.annotations?.items ?? []).some(
                            (e) =>
                              e.originStatus ===
                              AnnotationOriginStatus.HumanGenerated,
                          )
                            ? aitem.originStatus ===
                              AnnotationOriginStatus.HumanGenerated
                            : aitem.originStatus ===
                                AnnotationOriginStatus.AiGeneratedHumanApproved ||
                              aitem.originStatus ===
                                AnnotationOriginStatus.AiGenerated
                        }
                      >
                        <li>
                          <div
                            classList={{
                              "border rounded-full p-1": true,
                              "bg-nl-red-800 border-nl-red-500":
                                props.classes?.[
                                  aitem.annotationData.singleLabelClassification
                                    .class
                                ] === AnnotationValuation.Bad,
                              "bg-nl-green-800 border-nl-green-500":
                                props.classes?.[
                                  aitem.annotationData.singleLabelClassification
                                    .class
                                ] === AnnotationValuation.Good,
                              "bg-nl-gray-200 border-nl-gray-500":
                                props.classes?.[
                                  aitem.annotationData.singleLabelClassification
                                    .class
                                ] === AnnotationValuation.Neutral,
                              "opacity-50":
                                aitem.originStatus ===
                                AnnotationOriginStatus.AiGenerated,
                            }}
                            aria-hidden="true"
                          />
                        </li>
                      </Show>
                    )}
                  </For>
                </div>

                <Show when={false}>
                  <div class="absolute">
                    <Switch
                      fallback={
                        <div
                          class="h-10 w-10 absolute top-4 right-4 bg-nl-gray-dark-200 text-white rounded-full p-3"
                          aria-hidden="true"
                        />
                      }
                    >
                      <Match when={item.annotationComplete}>
                        <CheckIcon />
                      </Match>
                      <Match
                        when={
                          !item.annotationComplete &&
                          item.annotations.items.length > 0
                        }
                      >
                        <ViewfinderCircleIcon />
                      </Match>
                    </Switch>
                  </div>
                </Show>
              </li>
            )}
          </For>
        </Suspense>
      </ul>

      <button
        aria-label="slide forward"
        class="absolute z-30 right-0 top-auto rounded first-letter:focus:outline-none focus:bg-nl-gray-dark-800 focus:ring-2 focus:ring-nl-gray-dark-400 cursor-pointer"
        id="NextImage"
        onClick={goNext}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
}
